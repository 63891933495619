<template>
  <v-container class="ma-0 pa-0" style="height: 500px; background: orange; max-width:100%;">
    CarSale
  </v-container>
</template>
<script>
export default {
  name: 'CarSale',
  data: () => ({
    // 
  }),
  async mounted() {
    // let result = await fetch('/getNews').then(async resp => {
    //   return await resp.json();
    // });
    // this.newsList = [...result];
  }
}
</script>
<style scoped>
</style>