<template>
  <v-container id="access-map" class="ma-0 pa-0" style="max-width:100%;">
    <div class="sticky" data-aos="zoom-in">
      <span class="ma-4 mt-0 text-border contents-title">Calendar</span>
      <span class="font-Yusei orange--text text--darken-2 contents-sub-title">カレンダー</span>
    </div>
    <div class="pa-4" data-aos="zoom-in">
      <v-toolbar
        flat
      >
        <v-toolbar-title v-if="$refs.calendar" class="text-h5 orange--text text--darken-2">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-btn
          v-if="showMonth == 'next'"
          fab
          text
          small
          color="grey darken-2"
          @click="prev"
        >
          <v-icon small>
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <v-btn
          v-if="showMonth == 'current'"
          fab
          text
          small
          color="grey darken-2"
          @click="next"
        >
          <v-icon small>
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-calendar
        ref="calendar"
        v-model="focus"
        color="orange lighten-1"
        :events="events"
        :show-month-on-first="false"
      ></v-calendar>
    </div>
  </v-container>
</template>
<script>
  export default {
    name: 'ShowCalendar',
    data() {
      return {
        focus: '',
        showMonth: 'current',
        events: [
          {
            name: '定休日',
            start: '2024-08-19',
            end: '2024-08-19',
            color: 'red',
          }
        ]
      }
    },
    methods: {
      prev() {
        this.showMonth = 'current';
        this.$refs.calendar.prev();
      },
      next() {
        this.showMonth = 'next';
        this.$refs.calendar.next();
      },
      getWeekDaysCurrent(weekNum) {
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth();
        const days = [];
        for (let i = 1; i <= 31; i++){
          const tmpDate = new Date(year, month, i);
          if (month !== tmpDate.getMonth()) break; //月代わりで処理終了
          if (tmpDate.getDay() !== weekNum) continue; //引数に指定した曜日以外の時は何もしない
          days.push(`${year}-${this.set00(month+1)}-${this.set00(tmpDate.getDate())}`);
        }
        return days;
      },
      getWeekDaysNext(weekNum) {
        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const days = [];
        for (let i = 1; i <= 31; i++){
          const tmpDate = new Date(year, month, i);
          if (month !== tmpDate.getMonth()) break; //月代わりで処理終了
          if (tmpDate.getDay() !== weekNum) continue; //引数に指定した曜日以外の時は何もしない
          days.push(`${year}-${this.set00(month+1)}-${this.set00(tmpDate.getDate())}`);
        }
        return days;
      },
      regularHolidaysCurrent() {
        const satDays = this.getWeekDaysCurrent(6);
        const sunDays = this.getWeekDaysCurrent(0);
        const holidays = [];
        if (satDays[4]) holidays.push(satDays[4]);
        holidays.push(...sunDays);
        return holidays.map(holiday => {
          return {
            date: holiday,
          };
        });
      },
      regularHolidaysNext() {
        const satDays = this.getWeekDaysNext(6);
        const sunDays = this.getWeekDaysNext(0);
        const holidays = [];
        if (satDays[4]) holidays.push(satDays[4]);
        holidays.push(...sunDays);
        return holidays.map(holiday => {
          return {
            date: holiday,
          };
        });
      },
      set00(num) {
        return ( '00' + num ).slice( -2 );
      }
    },
    async mounted() {
      let currentDate = new Date();
      let holidayList = {};
      let regularHolidayList = [...this.regularHolidaysCurrent(), ...this.regularHolidaysNext()];
      for (let holiday of regularHolidayList) {
        holidayList[holiday.date] = '';
      }
      try {
        await fetch(`https://holidays-jp.github.io/api/v1/${currentDate.getFullYear()}/date.json`).then(async resp => {
          return await resp.json();
        }).then(holiday => {
          Object.assign(holidayList, holiday);
        });
        if (currentDate.getMonth() == 11) {
          await fetch(`https://holidays-jp.github.io/api/v1/${currentDate.getFullYear() + 1}/date.json`).then(async resp => {
            return await resp.json();
          }).then(holiday => {
            Object.assign(holidayList, holiday);
          });
        }
      } catch(err) {
        console.error(err);
      }
      for (let key in holidayList) {
        this.events.push({
            name: holidayList[key] ? `定休日 [${holidayList[key]}]` : '定休日',
            start: key,
            end: key,
            color: 'red',
        });
      }
    }
  }
</script>
<style scoped>
</style>