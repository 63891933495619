<template>
  <v-footer
    dark
    padless
  >
    <v-card
      flat
      tile
      width="100%"
      class="orange darken-2 white--text text-center"
    >
      <v-card-text class="white--text pt-0 footer-logo">
        <img src="https://mori-motors.com/img/mori_motors_new_logo.png" alt="https://mori-motors.com/img/mori_motors_new_logo.png">
      </v-card-text>
      <v-card-text>
        <v-btn
          v-for="data in icons"
          :key="data"
          class="mx-4 white--text"
          icon
          :href="data.href"
        >
          <v-icon size="24px">
            {{data.icon}}
          </v-icon>
        </v-btn>
      </v-card-text>
      <v-card-text class="white--text pt-0">{{name}}</v-card-text>
      <v-card-text class="white--text pt-0">{{address}}</v-card-text>
      <v-card-text class="white--text pt-0">電話番号 : <a :href="'tel:' + phone" style="color:#fff;">{{phone}}</a></v-card-text>
      <v-card-text class="white--text pt-0">Fax : {{fax}}</v-card-text>
      <v-divider></v-divider>

      <v-card-text class="white--text footer-text">
        {{ new Date().getFullYear() }} — <img src="https://mori-motors.com/img/mori_motors_title.svg" alt="https://mori-motors.com/img/mori_motors_title.svg">
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
  export default {
    name: 'BottomFooter',

    data: () => ({
      icons: [
        {
          icon: 'mdi-facebook',
          href: 'https://www.facebook.com/newmorimotors/'
        },
        // {
        //   icon: 'mdi-instagram',
        //   href: ''
        // },
      ],
      name: '森モータース',
      address: '〒904-1114 沖縄県うるま市石川嘉手苅90-5',
      phone: '098-988-7523',
      fax: '098-988-7524',
    }),
  }
</script>
<style scoped>
  .footer-logo {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer-logo img {
    height: 300px;
  }
  .footer-text {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  .footer-text img {
    height: 30px;
  }
</style>
