<template>
  <div id="slide-contents">
    <v-img
      v-for="(slide, i) in slides"
      :key="i"
      :src="`https://mori-motors.com/img/${slide.img}`"
      eager
      :height="height"
      width="100%"
      class="slide-item"
      :gradient="slide.gradient"
    ></v-img>
    <div class="top-text font-poetsen">
      <span>Maintenace</span>
      <br>
      <span>And</span>
      <br>
      <span>Car Sales</span>
    </div>
    <div class="scroll-animation">scroll</div>
  </div>
</template>

<script>
  export default {
    name: 'SlideShow',
    data: () => ({
      cycleTime: 6000,
      height: 600,
      slides: [
        {
          img: 'IMG_3650.jpg',
        },
        {
          img: 'image2.jpeg',
          gradient: 'to top right, rgb(221,151,55,.33), rgba(72,57,25,.9)'
        }
      ],
    }),
    mounted() {
      let slideItems = document.getElementsByClassName('slide-item');
      if (slideItems.length > 0) {
        slideItems[0].classList.add('shown');
        let shownNum = 0;
        setInterval(() => {
          while(document.getElementsByClassName('hidden').length > 0) document.getElementsByClassName('hidden')[0].classList.remove('hidden');
          slideItems[shownNum].classList.add('hidden');
          slideItems[shownNum].classList.remove('shown');
          shownNum++;
          if (shownNum >= slideItems.length) shownNum = 0;
          slideItems[shownNum].classList.add('shown');
        }, this.cycleTime);
      }
      document.addEventListener("scroll", () => {
        let slideContents = document.getElementById('slide-contents');
        let topHeader = document.getElementById('top-header');
        // let contentsContainer = document.getElementById('contents-container');
        if (slideContents.getBoundingClientRect().y + this.height <= 0) {
          topHeader.classList.add('recolor');
          // contentsContainer.classList.add('active');
        }
        else {
          topHeader.classList.remove('recolor');
          // contentsContainer.classList.remove('active');
        }
      });
    }
  }
</script>
<style scoped>
  #slide-contents {
    position: relative;
    height: 600px;
  }
  .top-text {
    z-index: 3;
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: auto;
    font-size: 50px;
    -webkit-text-stroke: 1px #000;
    color: #fff;
    letter-spacing: -3px;
    line-height: 1;
    text-align: right;
  }
  @media screen and (min-width:480px) { 
    .top-text {
      font-size: 100px;
    }
  }
  .slide-item {
    z-index: 0;
    position: absolute;
  }
  .slide-item.hidden {
    z-index: 1;
  }
  .slide-item.shown {
    z-index: 2;
    -webkit-animation: pageslide_key 2s cubic-bezier(0.8, 0, 0, 0.8) 1 forwards;
    animation: pageslide_key 2s cubic-bezier(0.8, 0, 0, 0.8) 1 forwards
  }
  @keyframes pageslide_key {
      0% {
          -webkit-clip-path: circle(0% at 0 0);
          clip-path: circle(0% at 0 0)
      }

      100% {
          -webkit-clip-path: circle(141.2% at 0 0);
          clip-path: circle(141.2% at 0 0)
      }
  }
  .scroll-animation {
    position: absolute;
    bottom: 25px;
    left: 10px;
    z-index: 2;
    color: #ffffff;
    animation: slide 1s ease 0s infinite;
    /* font-family: "Monoton", sans-serif !important; */
    font-family: "Poetsen One", sans-serif !important;
    font-size: 22px;
  }
  .scroll-animation::before {
    content: "";
    position: absolute;
    top: 5px;
    right: 0;
    width: 70px;
    height: 2px;
    background: #fff;
  }
  .scroll-animation::after {
    content: "";
    position: absolute;
    bottom: 19px;
    right: 53px;
    width: 16px;
    height: 16px;
    transform: rotate(45deg);
    border-bottom-width: 2px;
    border-left-width: 2px;
    border-bottom-style: solid;
    border-left-style: solid;
    border-bottom-color: rgb(255, 255, 255);
    border-left-color: rgb(255, 255, 255);
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
  }
  @keyframes slide {
    0%, 100% {
      transform: translateY(0) rotate(270deg);
    }
    50% {
      transform: translateY(-20px) rotate(270deg);
    }
  }
</style>