<template>
  <v-app>
    <MainHeader/>
    <router-view/>
    <BottomFooter/>
  </v-app>
</template>

<script>
import MainHeader from './components/MainHeader';
import BottomFooter from './components/BottomFooter';

export default {
  name: 'App',
  components: {
    MainHeader,
    BottomFooter,
  },
  data: () => ({
    //
  }),
  mounted() {
    window.AOS.init();
  }
};
</script>
<style>
  .text-border {
    -webkit-text-stroke: 2px #000;
    color: #fff;
    font-size: 80px;
    font-family: "Poetsen One", sans-serif;
  }
  .font-poetsen {
    font-family: "Poetsen One", sans-serif !important;
  }
  .font-Monoton {
    font-family: "Monoton", sans-serif !important;
  }
  .font-Yusei {
    font-family: "Yusei Magic", sans-serif !important;
  }
  .sticky {
    position: sticky;
    top: 38px;
    z-index: 10;
  }
  .contents-title {
    color: #fbad39;
    font-size: 3rem;
    -webkit-text-stroke: 2px #000;
    z-index: 10;
  }
  .contents-sub-title {
    color: #ffeacb;
    font-size: 1.2rem;
    -webkit-text-stroke: 0.5px #000;
    z-index: 10;
  }
  @media screen and (min-width:480px) { 
    .contents-title {
      color: #fbad39;
      font-size: 4rem;
      -webkit-text-stroke: 2px #000;
      z-index: 10;
    }
    .contents-sub-title {
      color: #ffeacb;
      font-size: 1.4rem;
      -webkit-text-stroke: 0.5px #000;
      z-index: 10;
    }
  }
</style>
<style scoped>
.top-button {
    bottom: 10px;
    right: 10px;
    position: fixed;
    z-index: 20;
  }
</style>