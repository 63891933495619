import Vue from 'vue'
import Router from 'vue-router'
import MainApp from './components/MainApp'
import ControlPanel from './components/ControlPanel'
import CarSale from './components/CarSale'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'MainApp',
      component: MainApp
    },
    {
      path: '/management',
      name: 'ControlPanel',
      component: ControlPanel
    },
    {
      path: '/sale',
      name: 'CarSale',
      component: CarSale
    }
  ]
})
