<template>
  <v-container class="ma-0 pa-0">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="400px"
    >
      <v-card>
        <v-card-title>
          <v-icon color="orange darken-2">mdi-account</v-icon>
          <span class="text-h5 orange--text text--darken-2">Login</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="id"
                label="ID"
                color="orange"
                placeholder="IDを入力してください"
                :rules="[rules.required]"
                :error-messages="idError"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="pass"
                label="Password"
                color="orange"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPass ? 'text' : 'password'"
                :rules="[rules.required, rules.min]"
                :error-messages="passError"
                placeholder="パスワードを入力してください"
                @click:append="showPass = !showPass"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="3">
              <v-btn dark color="orange" :loading="loading" @click="login">
                <v-icon left>
                  mdi-login
                </v-icon>
                Login
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-app-bar color="orange" app>
      <span class="font-Yusei" style="position: absolute;right: 20px;color: rgb(255, 255, 255);">管理画面</span>
    </v-app-bar>
    <div style="margin-top:56px;">
      <span class="ma-4 mt-0 text-border contents-title">News</span>
      <span class="font-Yusei orange--text text--darken-2 contents-sub-title">最新情報</span>
    </div>
    <v-data-table
      :headers="newsHeaders"
      :items="newsItems"
      sort-by="id"
      class="elevation-1 ma-2"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>News Table</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            color="orange"
            dark
            class="mb-2"
            @click="openNewsDialog('Add')"
          >
            <v-icon left>mdi-plus</v-icon>
            Add News
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:[`item.icon`]="{ item }">
        <v-icon>{{item.icon}}</v-icon>
      </template>
      <template v-slot:[`item.image`]="{ item }">
        <v-img width="60" :src="`https://mori-motors.com/img/news/${item.image}`"></v-img>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="openNewsDialog('Edit', item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="openNewsDialog('Delete', item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog v-model="newsDialog">
      <v-card>
        <v-card-title>
          <v-icon color="orange darken-2">mdi-account</v-icon>
          <span class="text-h5 orange--text text--darken-2">{{newsDialogType}}</span>
        </v-card-title>
        <v-card-text v-if="newsDialogType == 'Delete'">
          <v-row>
            <v-col cols="12">
              削除してもよろしいですか？
            </v-col>
          </v-row>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" md="4">
              <v-btn dark color="red" :loading="editLoading" @click="crudNews(newsDialogType)">
                <v-icon left>
                  mdi-delete-outline
                </v-icon>
                削除
              </v-btn>
              <v-btn class="ml-2" dark color="grey" @click="closeNewsDialog">
                <v-icon left>
                  mdi-cancel
                </v-icon>
                キャンセル
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else>
          <v-row>
            <v-col class="pt-0" cols="3" md="1">
              <v-text-field
                v-model="newsDialogObject.id"
                label="ID"
                color="orange"
                disabled
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="9" md="3">
              <v-select
                :items="iconList"
                v-model="newsDialogObject.icon"
                label="Icon"
                color="orange"
                outlined
                dense
              >
                <template v-slot:prepend>
                  <v-icon>{{newsDialogObject.icon}}</v-icon>
                </template>
              </v-select>
            </v-col>
            <v-col class="pt-0" cols="6" md="8">
              <v-menu
                ref="datePicker"
                v-model="datePicker"
                :close-on-content-click="false"
                :return-value.sync="newsDialogObject.date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-combobox
                    v-model="newsDialogObject.date"
                    chips
                    small-chips
                    label="Date"
                    color="orange"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-combobox>
                </template>
                <v-date-picker
                  v-model="newsDialogObject.date"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="datePicker = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.datePicker.save(newsDialogObject.date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="pt-0" cols="6" md="4">
              <v-text-field
                v-model="newsDialogObject.title"
                label="Title"
                color="orange"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="12" md="8">
              <v-text-field
                v-model="newsDialogObject.text"
                label="Text"
                color="orange"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col class="pt-0" cols="12">
              <v-textarea
                v-model="newsDialogObject.detail"
                label="Detail"
                color="orange"
                outlined
                dense
              ></v-textarea>
            </v-col>
            <v-col class="pt-0" cols="12">
              <v-file-input
                v-model="newsDialogObject.image"
                label="Image"
                accept="image/*"
                multiple
                outlined
                dense
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" md="4">
              <v-btn dark color="orange" :loading="editLoading" @click="crudNews(newsDialogType)">
                <v-icon left>
                  {{newsDialogType == 'Add' ? 'mdi-plus' : 'mdi-check'}}
                </v-icon>
                {{newsDialogType == 'Add' ? '追加' : '更新'}}
              </v-btn>
              <v-btn class="ml-2" dark color="grey" @click="closeNewsDialog">
                <v-icon left>
                  mdi-cancel
                </v-icon>
                キャンセル
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
export default {
  name: 'ControlPanel',
  data: () => ({
    // dialog: true,
    dialog: false,
    showPass: false,
    loading: false,
    id: '',
    pass: '',
    idError: '',
    passError: '',
    rules: {
      required: value => !!value || '必須です.',
      min: v => v.length >= 8 || '8文字以上入力してください',
    },
    newsDialog: false,
    newsDialogType: '',
    editLoading: false,
    newsDialogObject: {},
    newsHeaders: [
      { text: 'Id', value: 'id', width: '61px' },
      { text: 'Title', value: 'title' },
      { text: 'Text', value: 'text' },
      { text: 'Detail', value: 'detail' },
      { text: 'Date', value: 'date' },
      { text: 'Icon', value: 'icon', width: '74px' },
      { text: 'Image', value: 'image', width: '60px' },
      { text: 'Actions', value: 'actions', sortable: false },],
    newsItems: [],
    newsList: [
      {
        icon: 'mdi-map-marker-alert',
        create_date: '2024-06-17',
        title: '工場移転しました',
        text: '詳しくはクリック',
        detail: '同じうるま市石川内に移転しております。ホームページ内に掲載されているGooglMapをご確認ください。',
        detail_image: 'new_location.jpg'
      },
      {
        icon: 'mdi-application-outline',
        create_date: '2024-06-17',
        title: 'ホームページが公開されました',
        text: '当社のホームページを公開しました',
      }
    ],
    iconList: [
      'mdi-map-marker-alert',
      'mdi-application-outline',
      'mdi-alert',
      'mdi-bell-ring-outline',
      'mdi-wrench-outline',
      'mdi-halloween',
      'mdi-heart',
      'mdi-star',
      'mdi-weather-dust',
      'mdi-facebook',
      'mdi-instagram',
    ],
    datePicker: false
  }),
  methods: {
    login: async function () {
      this.loading = true;
      this.idError = '';
      this.passError = '';
      // リクエストの構築
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      const request = new Request('https://mori-motors.com/login', {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({id: this.id, pass: this.pass})
      });
      try {
        await fetch(request).then(async resp => {
          return await resp.json();
        }).then(result => {
          console.log(result);
          if (result == 'success') {
            this.loading = false;
            this.dialog = false;
            localStorage.setItem('auth', JSON.stringify({ id: this.id, pass: this.pass }));
            this.id = '';
            this.pass = '';
          }
          else {
            this.idError = 'IDまたはPasswordが違います';
            this.passError = 'IDまたはPasswordが違います';
            this.loading = false;
          }
        });
      } catch(err) {
        console.error(err);
        this.idError = 'サーバー接続に失敗しました';
        this.passError = 'サーバー接続に失敗しました';
        this.loading = false;
      }
    },
    getNewsList: async function () {
      await fetch('/getNewsAll').then(async resp => {
        return await resp.json();
      }).then(result => {
        this.newsItems = result.map(item => {
          item['date'] = item['create_date'];
          delete item['create_date'];
          item['image'] = item['detail_image'];
          delete item['detail_image'];
          return item;
        });
      });
    },
    openNewsDialog: function (type, news) {
      console.log('news');
      console.log(news);
      this.newsDialog = true;
      this.newsDialogType = type;
      if (news) this.newsDialogObject = JSON.parse(JSON.stringify(news));
    },
    closeNewsDialog: function () {
      this.newsDialog = false;
      this.newsDialogType = '';
      this.newsDialogObject = {};
    },
    crudNews: async function (type) {
      let method;
      switch (type) {
        case 'Add':
          method = 'POST';
          break;
        case 'Edit':
          method = 'PUT';
          break;
        case 'Delete':
          method = 'DELETE';
          break;
      }
      // リクエストの構築
      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      const auth = localStorage.getItem('auth');
      headers.append('auth-data', auth);
      const body = {
        value: JSON.parse(JSON.stringify(this.newsDialogObject))
      };
      if (body.value['date']) {
        body.value['create_date'] = body.value['date'];
        delete body.value['date'];
      }
      
      try {
        let imageInfo;
        if (this.newsDialogObject.image && Array.isArray(this.newsDialogObject.image) && this.newsDialogObject.image.length > 0) {
          console.log('this.newsDialogObject.image');
          console.log(this.newsDialogObject.image);
          const fd = new FormData();
          fd.append('file', this.newsDialogObject.image[0]);

          const postHeaders = new Headers();
          postHeaders.append('auth-data', auth);
          const postRequest = new Request('https://mori-motors.com/newsImage', {
            method: 'POST',
            headers: postHeaders,
            body: fd
          });
          imageInfo = await fetch(postRequest).then(async resp => {
            console.log(resp);
            return await resp.json();
          });
          body.value['detail_image'] = imageInfo;
        }
        if (body.value['image']) delete body.value['image'];
        const request = new Request('https://mori-motors.com/news', {
          method: method,
          headers: headers,
          body: JSON.stringify(body)
        });
        await fetch(request).then(async resp => {
          return await resp.json();
        }).then(async result => {
          console.log(result);
          await this.getNewsList();
          this.closeNewsDialog();
        });
      } catch(err) {
        console.error(err);
        this.idError = 'サーバー接続に失敗しました';
        this.passError = 'サーバー接続に失敗しました';
        this.loading = false;
      }
    }
  },
  async mounted() {
    if (location.hostname == 'localhost') {
      this.dialog = false;
      this.newsItems = this.newsList.map((item, index) => {
        item['id'] = index + 1;
        item['date'] = item['create_date'];
        delete item['create_date'];
        item['image'] = item['detail_image'];
        delete item['detail_image'];
        return item;
      });
    }
    else {
      try {
        await this.getNewsList();
      } catch(err) {
        console.error(err);
      }
      const auth = localStorage.getItem('auth');
      if (auth) {
        try {
          const authJson = JSON.parse(auth);
          // リクエストの構築
          const headers = new Headers();
          headers.append('Content-Type', 'application/json');
          const request = new Request('https://mori-motors.com/login', {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({id: authJson.id, pass: authJson.pass})
          });
          await fetch(request).then(async resp => {
            return await resp.json();
          }).then(result => {
            console.log(result);
            if (result == 'success') {
              this.dialog = false;
            }
          });
        } catch(err) {
          console.error(err);
        }
      }
    }
  }
}
</script>
<style scoped>
  .container {
    min-height: 500px;
    max-width: 100%;
    /* background-color: #FB8C00BA; */
  }
</style>