<template>
  <v-main class="pa-0">
    <ShowLoading/>
    <SlideShow/>
    <NewsList/>
    <AboutUs/>
    <ContentDescription/>
    <AccessMap/>
    <ShowCalendar/>
  </v-main>
</template>
<script>
import ShowLoading from './ShowLoading';
import SlideShow from './SlideShow';
import NewsList from './NewsList';
import AboutUs from './AboutUs';
import ContentDescription from './ContentDescription';
import AccessMap from './AccessMap';
import ShowCalendar from './ShowCalendar';
export default {
  name: 'MainApp',
  components: {
    ShowLoading,
    SlideShow,
    NewsList,
    AboutUs,
    ContentDescription,
    AccessMap,
    ShowCalendar,
  },
  data: () => ({
    // 
  }),
}
</script>
<style scoped>
</style>