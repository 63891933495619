<template>
  <v-container id="access-map" class="ma-0 pa-0" style="max-width:100%;">
    <div class="sticky" data-aos="zoom-in">
      <span class="ma-4 mt-0 text-border contents-title">Map</span>
      <span class="font-Yusei orange--text text--darken-2 contents-sub-title">地図</span>
    </div>
    <iframe data-aos="zoom-in" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3471.4143378562785!2d127.81545462065014!3d26.419135280534032!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x34e50ff40e41e145%3A0xf7087f45aba01a87!2zTW9yaSBNb3RvcnMg5qOu44Oi44O844K_44O844K5IC0gSkNJIEluc3BlY3Rpb25zLCBSZXBhaXJzIGFuZCBCb2R5d29yaw!5e0!3m2!1sja!2sjp!4v1718954407362!5m2!1sja!2sjp" width="90%" height="600" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
  </v-container>
</template>
<script>
  export default {
    name: 'AccessMap',
    data: () => ({
      // 
    }),
  }
</script>
<style scoped>
  #access-map {
    position: relative;
    z-index: 10;
    background: linear-gradient(to bottom, #FFF3E0, #FFFFFF);
  }
  iframe {
    border-radius: 10px;
    margin-left: 5%;
  }
</style>